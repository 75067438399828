import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import lodash from 'lodash';

// Custom components
import { Card, CardContent, CardHeader } from '../../../../components/ui/card';
import { Separator } from '../../../../components/ui/separator';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../../../components/ui/tabs";
import ProductQuestionsPreview from './ProductQuestionsPreview';
import { ProductHeader } from './ProductHeader';
import ProductReportsView from './ProductReportsView';
import ProductNotesView from './ProductNotesView';
import ProductChatView from './ProductChatView';
import KnowledgeBaseSection from '../../knowledge_base/KnowledgeBaseSection';
import ProductTasksView from './ProductTasksView';

// Services
import { generateProductReport, getProduct } from '../../../../services/api';
import { logError } from '../../../../services/logging_utils';
import { checkIfNoReportsAreGeneratedForProduct } from '../../reports_utils';
// State
import { productReportsState } from '../../../../store/store';

export default function ProductView({
    activeProduct,
    setProductsView,
    setActiveProduct,
    handleRedirectToAllProduct
}) {
    const [reports, setProductReports] = useRecoilState(productReportsState);

    const [product, setProduct] = useState(null);
    const [logs, setLogs] = useState(null);
    const [loadingProduct, setLoadingProduct] = useState(null);
    const [creatingProduct, setCreatingProductReports] = useState(false);

    const _getProduct = async () => {
        try {
            setLoadingProduct(true);

            const reportId = lodash.get(activeProduct, ['id']);
            const response = await getProduct(reportId);
            const product = lodash.get(response, ['data', 'data', 'product'], {});
            if (product) {
                setProduct(product);
                setProductReports(lodash.get(product, ['reports'], []).reduce((acc, report) => ({ ...acc, [report.id]: { ...report, checkbox: true } }), {}));


                setLogs(lodash.get(product, ['reports'], []).reduce((acc, report) => {
                    const reportId = report.id;
                    const sanitizedLogs = lodash.get(report, ['logs'], []).filter((log) => typeof log.output === 'string');
                    return { ...acc, [reportId]: sanitizedLogs };
                }, {}));

                return product;
            }
            return product;
        } catch (error) {
            logError(error);
            return null;
        } finally {
            setLoadingProduct(false);
        }
    }


    useEffect(() => {
        if (activeProduct !== null) {
            (async () => {
                await _getProduct();
            })();

            // Set up the interval for polling
            // const intervalId = setInterval(() => {
            //     _getProduct();
            // }, 30000); // 8000 milliseconds = 8 seconds

            // Clean up the interval on component unmount
            // return () => clearInterval(intervalId);
        }
    }, [activeProduct]);


    // Generates the reports for the product based on the answered product questions from the user
    const handleGenerateReportsFromQuestions = async (answeredQuestions, product) => {
        try {
            setCreatingProductReports(true);
            const payload = { ...product, questions: answeredQuestions };
            const response = await generateProductReport({ payload, productId: product.id });

            const updatedProduct = lodash.get(response, ['data', 'data', 'product']);
            setActiveProduct(updatedProduct);
            setProductsView('REPORT_VIEW');
        } catch (error) {
            logError(error);
        } finally {
            setCreatingProductReports(false);
        }
    };

    const handleUpdateProduct = async (report, action) => {
        await _getProduct();
    }

    // Showing the preview questions related to product to the user
    const noReports = checkIfNoReportsAreGeneratedForProduct(activeProduct);
    if (noReports) {
        // Render the Product questionaries
        return <ProductQuestionsPreview
            activeProduct={activeProduct}
            setProductsView={setProductsView}
            setActiveProduct={setActiveProduct}
            creatingProduct={creatingProduct}
            handleGenerateReportsFromQuestions={handleGenerateReportsFromQuestions} />;
    }

    return (
        <Card className="h-full">
            <CardHeader className="text-left flex flex-row justify-between w-full p-4">
                <ProductHeader loadingProduct={loadingProduct} product={product} handleRedirectToAllProduct={handleRedirectToAllProduct} />
            </CardHeader>

            <Separator />
            <CardContent className='w-full'>

                <Tabs defaultValue="reports" className='h-full w-full'>
                    <TabsList className="grid grid-cols-5 m-1 w-[600px]">
                        <TabsTrigger value="reports">Reports</TabsTrigger>
                        <TabsTrigger value="notes">Notes</TabsTrigger>
                        <TabsTrigger value="chat">Chat</TabsTrigger>
                        <TabsTrigger value="knowledge_base">Knowledge Base</TabsTrigger>
                        <TabsTrigger value="tasks">Tasks</TabsTrigger>
                    </TabsList>

                    <TabsContent value="reports" className='p-1 border rounded m-1 product-view_tab-content'>
                        <ProductReportsView
                            logs={logs}
                            product={product}
                            loadingProduct={loadingProduct}
                            setProductsView={setProductsView}
                            setActiveProduct={setActiveProduct}
                            handleUpdateProduct={handleUpdateProduct}
                        />
                    </TabsContent>

                    <TabsContent value="notes" className='p-1 border rounded m-1 product-view_tab-content'>
                        <ProductNotesView
                            logs={logs}
                            product={product}
                            loadingProduct={loadingProduct}
                            setProductsView={setProductsView}
                            setActiveProduct={setActiveProduct}
                            handleUpdateProduct={handleUpdateProduct}
                        />
                    </TabsContent>

                    <TabsContent value="chat" className='h-full p-1 border rounded m-1 product-view_tab-content'>
                        <ProductChatView
                            product={product}
                        />
                    </TabsContent>

                    <TabsContent value="knowledge_base" className='h-full p-1 border rounded m-1 product-view_tab-content'>
                        <KnowledgeBaseSection
                            scope="knowledge_base_chat"
                            product={product}
                        />
                    </TabsContent>

                    <TabsContent value="tasks" className='h-full p-1 border rounded m-1 product-view_tab-content'>
                        <ProductTasksView
                            product={product}
                        />
                    </TabsContent>
                </Tabs>
            </CardContent>
        </Card >
    );
}
