import React from 'react';
import {
    Dialog,
    DialogOverlay,
    DialogContent,
    DialogTitle,
    DialogDescription,
    DialogClose
} from '../../../../components/ui/dialog';
import { Button } from '../../../../components/ui/button';

const ConfirmDeleteTaskDialog = ({ isOpen, onClose, onDeleteConfirmed, task }) => {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete the task "{task?.title}"?
                </DialogDescription>
                <div className="mt-4 flex justify-end">
                    <Button variant="outline" onClick={onClose}>Cancel</Button>
                    <Button className="ml-2" onClick={() => { onDeleteConfirmed(task.id); onClose(); }}>Delete</Button>
                </div>
                <DialogClose />
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmDeleteTaskDialog; 