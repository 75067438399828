import {
    atom,
} from 'recoil';

export const userState = atom({
    key: 'userState',
    default: null,
});

export const productsState = atom({
    key: 'productsState',
    default: [],
});

export const activeProductState = atom({
    key: 'activeProductState',
    default: null,
});

export const productReportsState = atom({
    key: 'productReportsState',
    default: [],
});

export const selectedReportState = atom({
    key: 'selectedReportState',
    default: null,
});

export const reportsState = atom({
    key: 'reportsState',
    default: [],
});

export const userSubscriptionState = atom({
    key: 'userSubscriptionState',
    default: null,
});

export const charactersState = atom({
    key: 'charactersState',
    default: [],
});

export const userCharacterChats = atom({
    key: 'userCharacterChats',
    default: [],
});

export const subscriptionPlansState = atom({
    key: 'subscriptionPlansState',
    default: [],
});

export const systemConstantsState = atom({
    key: 'systemConstants',
    default: {},
});

export const userProductsState = atom({
    key: 'userProductsState',
    default: [],
});