import React, { useEffect, useState } from 'react';
import { MoreHorizontal, Package } from 'lucide-react';
import { useRecoilState } from 'recoil'; // Import useRecoilState
import { getAllTasksForAuthenticatedUser, deleteTask, addTask, updateTask } from '../../../services/api'; // Ensure updateTask is included
import { userProductsState } from '../../../store/store'; // Import the new products state
import {
    Table,
    TableHeader,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
} from '../../../components/ui/table';
import { Loader2 } from 'lucide-react';
import { Card, CardHeader, CardContent, CardTitle, CardDescription } from '../../../components/ui/card'; // Remove CardFooter import
import { Button } from '../../../components/ui/button'; // Import Button component
import AddEditTaskDialog from './components/AddEditTaskDialog'; // Import the dialog component
import ConfirmDeleteTaskDialog from './components/ConfirmDeleteTaskDialog'; // Import the confirmation dialog
import {
    Menubar,
    MenubarMenu,
    MenubarTrigger,
    MenubarContent,
    MenubarItem,
    MenubarSeparator,
} from '../../../components/ui/menubar'; // Import Menubar components
import { Separator } from '../../../components/ui/separator'; // Import Separator component

const TasksView = () => {
    const [tasks, setTasks] = useState([]);
    const [products, setProducts] = useRecoilState(userProductsState); // Use Recoil state for products
    const [loading, setLoading] = useState(true);
    const [showAddEditDialog, setShowAddEditDialog] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null); // For editing tasks
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete confirmation dialog

    const fetchTasksAndProducts = async () => {
        setLoading(true);
        try {
            const response = await getAllTasksForAuthenticatedUser();
            setTasks(response.tasks);
            setProducts(response.products);
        } catch (error) {
            console.error("Error fetching tasks:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTasksAndProducts();
    }, [setProducts]);

    const handleEditTask = (task) => {
        setSelectedTask(task);
        setShowAddEditDialog(true);
    };

    const handleDeleteTask = (task) => {
        setSelectedTask(task); // Set the selected task for confirmation
        setDeleteDialogOpen(true); // Open the delete confirmation dialog
    };

    const confirmDeleteTask = async (taskId) => {
        try {
            await deleteTask(taskId);
            await fetchTasksAndProducts(); // Refetch tasks after deletion
        } catch (error) {
            console.error("Error deleting task:", error);
        }
    };

    const handleSaveTask = async (productId, taskData) => {
        if (selectedTask) {
            await updateTask(selectedTask.id, taskData);
            setTasks(tasks.map(task => (task.id === selectedTask.id ? { ...task, ...taskData } : task)));
        } else {
            const newTask = await addTask(productId, taskData);
            setTasks([...tasks, newTask]);
        }

        await fetchTasksAndProducts();
    };

    const handleCloseDialog = () => {
        setShowAddEditDialog(false);
        setSelectedTask(null); // Reset selected task when closing
    };

    if (loading) {
        return (
            <div className="flex justify-center">
                <Loader2 className="animate-spin h-6 w-6" />
            </div>
        );
    }

    return (
        <Card className="h-full w-full m-2">
            <CardHeader className='text-left bg-muted flex flex-row items-center'>
                <Package className="h-8 w-8 transition-all group-hover:scale-110 mr-4" />
                <div className='flex flex-col'>
                    <CardTitle>Tasks</CardTitle>
                    <CardDescription>List of all tasks for all the products.</CardDescription>
                </div>
            </CardHeader>
            <Separator className="my-2" />
            <CardContent>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Product</TableHead>
                            <TableHead>Title</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Due Date</TableHead>
                            <TableHead>Priority</TableHead>
                            <TableHead>Assigned To</TableHead>
                            <TableHead>Actions</TableHead> {/* New column for actions */}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {tasks && tasks.length > 0 ? (
                            tasks.map(task => {
                                const product = products.find(p => p.id === task.product_id); // Find the associated product
                                return (
                                    <TableRow key={task.id} className="hover:bg-muted/50 text-left">
                                        <TableCell>{product ? product.name : 'Unknown Product'}</TableCell>
                                        <TableCell>{task.title}</TableCell>
                                        <TableCell>{task.description}</TableCell>
                                        <TableCell>{task.status}</TableCell>
                                        <TableCell>{task.due_date ? new Date(task.due_date).toLocaleDateString() : 'N/A'}</TableCell>
                                        <TableCell>{task.priority || 'N/A'}</TableCell>
                                        <TableCell>{task.assigned_to || 'N/A'}</TableCell>
                                        <TableCell>
                                            <Menubar>
                                                <MenubarMenu>
                                                    <MenubarTrigger>
                                                        <MoreHorizontal />
                                                    </MenubarTrigger>
                                                    <MenubarContent>
                                                        <MenubarItem onClick={() => handleEditTask(task)}>Edit</MenubarItem>
                                                        <MenubarSeparator />
                                                        <MenubarItem onClick={() => handleDeleteTask(task)}>Delete</MenubarItem>
                                                    </MenubarContent>
                                                </MenubarMenu>
                                            </Menubar>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8} className="text-center">No tasks available</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

                <div className="flex justify-start space-x-2 mt-4">
                    <Button variant="link" size="sm" onClick={() => setShowAddEditDialog(true)}>
                        + Add Task
                    </Button>
                </div>

                <AddEditTaskDialog
                    action={selectedTask ? 'edit' : 'add'}
                    task={selectedTask}
                    isOpen={showAddEditDialog}
                    onSave={handleSaveTask}
                    onClose={handleCloseDialog}
                    products={products} // Pass products to the dialog
                />
                <ConfirmDeleteTaskDialog
                    isOpen={isDeleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                    onDeleteConfirmed={confirmDeleteTask}
                    task={selectedTask}
                />
            </CardContent>
        </Card >
    );
};

export default TasksView; 