import React, { useState } from 'react';
import lodash from 'lodash';
import { ChevronLeft, Loader } from 'lucide-react';
// Custom components
import { Button } from '../../../../components/ui/button';
import { Skeleton } from '../../../../components/ui/skeleton';
import { EditProductDialog } from './EditProductDialog';
import { DeleteProductDialog } from './DeleteProductDialog';
import { DetailsDialog } from '../../../../components/DetailsDialog';
import { AnsweredProductQuestionsSheet } from '../../../../components/AnsweredProductQuestionsSheet';

function ProductDetails({ product, name, description, loadingProduct }) {

    if (loadingProduct && product === null) {
        return (
            <>
                <div className="flex flex-col w-full items-start">
                    <Skeleton className="h-[15px] w-[200px] my-1 rounded-md" />
                    <Skeleton className="h-[12px] w-[250px]  rounded-md" />
                </div>
            </>
        )
    }

    return (
        <>
            <div className="flex flex-col w-full items-start">
                <div className='flex items-center'>
                    <h2 className="text-xl font-semibold text-primary">{name}</h2>
                    <DetailsDialog heading={'Product Details'} title={name} description={description} />
                    {loadingProduct && product !== null ? <Loader className='w-4 h-4 ml-4' /> : null}
                </div>
                <p className="text-xs text-ellipsis max-w-2xl">{description} </p>
            </div>
        </>
    )
}


export const ProductHeader = ({ loadingProduct, product, handleRedirectToAllProduct }) => {
    const [editDialogStatus, setEditDialogStatus] = useState(null);
    const [deleteDialogStatus, setDeleteDialogStatus] = useState(null);

    return (
        <div className=" flex items-start justify-between flex-row w-full">
            <div className="flex items-center">
                <Button variant="outline" className="mr-4" onClick={handleRedirectToAllProduct}><ChevronLeft className="h-4 w-4" /> Back </Button>
                <ProductDetails product={product} loadingProduct={loadingProduct} name={lodash.get(product, ['name'])} description={lodash.get(product, ['description'])} />
            </div>
            <div className=" flex space-x-2 sm:justify-end">
                <div className="hidden space-x-2 md:flex">
                    <AnsweredProductQuestionsSheet product={product} />
                    <DeleteProductDialog product={product} setDialogStatus={setDeleteDialogStatus} isOpen={deleteDialogStatus} productDeletedCb={handleRedirectToAllProduct} />
                    <EditProductDialog product={product} setDialogStatus={setEditDialogStatus} isOpen={editDialogStatus} />
                </div>
            </div>
        </div>
    );
}

export default ProductHeader