import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil'; // Import useRecoilState
import { userProductsState } from '../../../../store/store'; // Import the new products state

import { getTasksByProductId, addTask, deleteTask } from '../../../../services/api';
import {
    Table,
    TableHeader,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
} from '../../../../components/ui/table';
import { Button } from '../../../../components/ui/button';
import ConfirmDeleteTaskDialog from './ConfirmDeleteTaskDialog';
import AddEditTaskDialog from './AddEditTaskDialog';
import {
    Menubar,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarTrigger,
} from '../../../../components/ui/menubar';
import { Loader2, MoreHorizontal } from 'lucide-react';

/**
 * ProductTasksView component displays the tasks related to a product.
 * 
 * @param {Object} props - Component props
 * @param {Object} props.product - The product object containing task details
 * @returns {JSX.Element} Rendered component
 */
const ProductTasksView = ({ product }) => {
    const [tasks, setTasks] = useState([]);
    const [products, setProducts] = useRecoilState(userProductsState); // Use Recoil state for products
    const [loading, setLoading] = useState(true);
    const [showAddEditDialog, setShowAddEditDialog] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const fetchTasks = async () => {
        setLoading(true);
        try {
            const response = await getTasksByProductId(product.id);
            setTasks(response.data);
        } catch (error) {
            console.error("Error fetching tasks:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (product?.id) {
            fetchTasks();
        }
    }, [product]);

    const handleAddTask = async (taskData) => {
        try {
            const taskWithTimestamp = {
                ...taskData,
                dueDate: taskData.dueDate.getTime(),
            };

            await addTask(product.id, taskWithTimestamp);
            setTasks([...tasks, taskWithTimestamp]);
            setShowAddEditDialog(false);
        } catch (error) {
            console.error("Error adding task:", error);
        }
    };

    const handleEditTask = async (taskData) => {
        setShowAddEditDialog(false);
    };

    const handleDeleteTask = async (taskId) => {
        try {
            await deleteTask(taskId);
            await fetchTasks();
        } catch (error) {
            console.error("Error deleting task:", error);
        }
    };

    const handleCloseDialog = () => {
        setShowAddEditDialog(false);
        setSelectedTask(null);
    };

    return (
        <div>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead>Title</TableHead>
                        <TableHead>Description</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Due Date</TableHead>
                        <TableHead>Priority</TableHead>
                        <TableHead>Assigned To</TableHead>
                        <TableHead>Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {loading ? (
                        <TableRow>
                            <TableCell colSpan={7} className="text-center">
                                <Loader2 className="animate-spin h-6 w-6 mx-auto" />
                            </TableCell>
                        </TableRow>
                    ) : (
                        tasks.map(task => (
                            <TableRow key={task.id} className="hover:bg-muted/50 text-left">
                                <TableCell>{task.title}</TableCell>
                                <TableCell>{task.description}</TableCell>
                                <TableCell>{task.status}</TableCell>
                                <TableCell>{new Date(task.dueDate).toLocaleDateString()}</TableCell>
                                <TableCell>{task.priority}</TableCell>
                                <TableCell>{task.assignedTo}</TableCell>
                                <TableCell>
                                    <Menubar>
                                        <MenubarMenu>
                                            <MenubarTrigger>
                                                <MoreHorizontal className="h-4 w-4" />
                                            </MenubarTrigger>
                                            <MenubarContent>
                                                <MenubarItem onClick={() => { setSelectedTask(task); setShowAddEditDialog(true); }}>Edit</MenubarItem>
                                                <MenubarItem onClick={() => { setSelectedTask(task); setDeleteDialogOpen(true); }}>Delete</MenubarItem>
                                            </MenubarContent>
                                        </MenubarMenu>
                                    </Menubar>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>

            <div className="flex justify-start space-x-2 mt-4">
                <Button variant="link" size="sm" onClick={() => setShowAddEditDialog(true)}>
                    + Add Task
                </Button>
            </div>

            <ConfirmDeleteTaskDialog
                task={selectedTask}
                onDeleteConfirmed={handleDeleteTask}
                isOpen={isDeleteDialogOpen}
                setDialogStatus={setDeleteDialogOpen}
            />
            <AddEditTaskDialog
                action={selectedTask ? 'edit' : 'add'}
                task={selectedTask}
                isOpen={showAddEditDialog}
                setDialogStatus={setShowAddEditDialog}
                onSave={handleAddTask}
                onClose={handleCloseDialog}
                products={products}
            />
        </div>
    );
};

export default ProductTasksView; 