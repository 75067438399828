import React, { useState, useEffect } from 'react';
import { Package } from "lucide-react";
import { get } from 'lodash';

import {
    Dialog,
    DialogHeader,
    DialogDescription,
    DialogOverlay,
    DialogContent,
    DialogTitle,
    DialogClose,
} from '../../../../components/ui/dialog';
import { Button } from '../../../../components/ui/button';
import { Separator } from '../../../../components/ui/separator';
import { Input } from '../../../../components/ui/input';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../../../components/ui/select';
import DatePickerDemo from './DatePickerDemo';

const AddEditTaskDialog = ({ action, task, isOpen, onSave, products, onClose }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('');
    const [priority, setPriority] = useState('');
    const [assignedTo, setAssignedTo] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');

    useEffect(() => {
        if (task) {
            setTitle(get(task, 'title', ''));
            setDescription(get(task, 'description', ''));
            setStatus(get(task, 'status', ''));
            setPriority(get(task, 'priority', ''));
            setAssignedTo(get(task, 'assigned_to', ''));
            setDueDate(get(task, 'due_date') ? new Date(get(task, 'due_date')).toISOString().split('T')[0] : '');
            setSelectedProduct(get(task, 'product_id', ''));
        } else {
            setTitle('');
            setDescription('');
            setStatus('');
            setPriority('');
            setAssignedTo('');
            setDueDate('');
            setSelectedProduct('');
        }
    }, [task]);

    const handleSave = () => {
        const payload = {
            title,
            description,
            status,
            priority,
            assigned_to: assignedTo,
            due_date: dueDate,
        }
        onSave(selectedProduct, payload);
        onClose();
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogHeader className="flex flex-row items-center">
                    <Package className="h-8 w-8 transition-all group-hover:scale-110 mr-4" />
                    <div className="flex flex-col">
                        <DialogTitle>{task ? 'Edit Task' : 'Add Task'}</DialogTitle>
                        <DialogDescription>
                            Give a title and description for the task.
                        </DialogDescription>
                    </div>
                </DialogHeader>

                <Separator />

                <Input
                    label="Task Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter task title"
                />
                <Input
                    label="Task Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Enter task description"
                />
                <Select value={selectedProduct} onValueChange={(value) => setSelectedProduct(value)}>
                    <SelectTrigger>
                        <SelectValue placeholder="Select Product" />
                    </SelectTrigger>
                    <SelectContent>
                        {products.map(product => (
                            <SelectItem key={get(product, 'id')} value={get(product, 'id')}>
                                {get(product, 'name')}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                <Select onValueChange={(value) => setStatus(value)}>
                    <SelectTrigger>
                        <SelectValue placeholder="Select status" />
                    </SelectTrigger>
                    <SelectContent >
                        <SelectItem value="pending">Pending</SelectItem>
                        <SelectItem value="in_progress">In Progress</SelectItem>
                        <SelectItem value="done">Done</SelectItem>
                    </SelectContent>
                </Select>
                <Select onValueChange={(value) => setPriority(value)}>
                    <SelectTrigger>
                        <SelectValue placeholder="Select priority" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="high">High</SelectItem>
                        <SelectItem value="medium">Medium</SelectItem>
                        <SelectItem value="low">Low</SelectItem>
                    </SelectContent>
                </Select>
                <Input
                    label="Assigned To"
                    value={assignedTo}
                    onChange={(e) => setAssignedTo(e.target.value)}
                    placeholder="Enter assigned person's name"
                />
                <DatePickerDemo dueDate={dueDate} setDueDate={setDueDate} />
                <div className="flex justify-end mt-4">
                    <Button onClick={() => { onClose(); }}>Cancel</Button>
                    <Button onClick={handleSave} className="ml-2">Save</Button>
                </div>

            </DialogContent>
        </Dialog>
    );
};

export default AddEditTaskDialog; 